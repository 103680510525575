// tbtech scss
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
// 4. Include any default map overrides here
// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/helpers";
// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/vanilla-cookieconsent/dist/cookieconsent";
// 8. Add additional custom code here
a{
  font-weight: 700;
  color: #000 !important;
}

a.nav-link:hover,
a.nav-link.active{
  color: #00adb5 !important;
}

span{
  .headline { display: none; }
}
@media (min-width: 768px) {
  .card-media img {
    max-height: 450px;
  }
}
:root {
  --bs-body-font-family: "Sedan", "Serif";
  --bs-body-font-size: 1.2rem;
}
footer{
  color: #fff;
  background-color: #4b4f52;
  .nav-link{
    color: #fff !important;
  }
}
h1,
h2,
h3,
h4 {
  font-weight: 700;
}
h1{ font-size: 1.3rem;}
h2 {
  font-size: 1.25rem;
}
h3 {
  font-size: 1.2rem;
}
h4,
h5 {font-size: 1rem;}
svg {
    fill: #000;
    max-height: 40px;
    max-width: 40px;
}

/* CSS */
.cta {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 5px;
    border: solid 1px #4b4f52;
    color: #000;
}

.cta:hover {
    background-color: #00ADB5;
    border: solid 1px #00ADB5;
    color: #fff;
}

.arrow {
    border: solid;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 5px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.highlight {
    padding: 1.2rem;
    background-color: #f0f8ff;
}

a {
    text-decoration: none;
}

footer svg {
    fill: #fff;
}

header a:hover svg {
    fill: #00ADB5;
}

footer a:hover {
    transform: scale(1.1);
}

@keyframes slideIn {
    0% {
        transform: translateX(50%) scale(.2);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(100%) scale(0);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes expand {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0px);
    }
}

.animate .card-media {
    animation: slideIn 1.5s ease-in-out forwards;
}

.animate .card-content {
    transform: translateY(100%) scale(0);
    animation: slideUp .8s ease-in-out forwards .8s;
    overflow: hidden;
}

.gray-cover {
    opacity: .4;
}